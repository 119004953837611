import { SourceHeaderKey } from '@locam/common-services';
import { FetchError } from 'ofetch';

import { ApiOrganizationError, getOrganizationBaseUrl } from '~/utils/api/organization';

interface Origin {
  user: {
    id: string;
    role: string;
    email: string;
  };
}

interface TrackingSourceInHeader {
  version: number;
  trackingSourceId: string;
  createdAt: string | Date;
  referer?: string;
  ip?: string;
  userAgent?: string;
  parameters: Record<string, string>;
}

interface TrackingSourceUtm {
  source?: string;
  medium?: string;
  campaign?: string;
  term?: string;
  content?: string;
}

type TrackingSource = TrackingSourceInHeader & {
  utm: TrackingSourceUtm;
};

interface AnonymousOrganizationApiInfos {
  firstName?: string;
  lastName?: string;
  email?: string;
  siren?: string;
  sirenScore?: 'ACCEPTED' | 'REFUSED' | 'WAITING' | 'FAILED';
  phone?: string;
  organizationId?: string;
}

export interface AnonymousOrganizationApi {
  organizationId: string;
  sessionId: string;
  infos: AnonymousOrganizationApiInfos;

  // not available for front
  origin?: Origin;
  source?: TrackingSource;
  dtpfSynchronized?: boolean;
}

export class ApiOrganizationAnonymousOrganizationError extends ApiOrganizationError {}
export class ApiOrganizationAnonymousOrganizationNotFoundError extends ApiOrganizationAnonymousOrganizationError {}
export class ApiOrganizationMissingTrackingSourceError extends ApiOrganizationAnonymousOrganizationError {}

export async function getAnonymousOrganization() {
  try {
    const {
      $api: { authenticatedOrAnonymousFetch },
    } = useNuxtApp();

    return await authenticatedOrAnonymousFetch<AnonymousOrganizationApi>('/anonymous-organization', {
      baseURL: getOrganizationBaseUrl(),
    });
  } catch (error) {
    if (error instanceof FetchError && error.status === 404) {
      throw new ApiOrganizationAnonymousOrganizationNotFoundError('Anonymous organization not found', { cause: error });
    }

    throw new ApiOrganizationAnonymousOrganizationError('Failed to get anonymous organization', { cause: error });
  }
}

export async function createAnonymousOrganization() {
  const trackingSourceCookie = useCookie(SourceHeaderKey);
  const sessionCookie = useCookie(ANONYMOUS_SESSION_COOKIE_NAME);

  if (!trackingSourceCookie.value) {
    throw new ApiOrganizationMissingTrackingSourceError(
      'Failed to create anonymous organization, missing tracking source',
    );
  }

  const { sessionId } = await $fetch<AnonymousOrganizationApi>('/organization/anonymous', {
    baseURL: getOrganizationBaseUrl(),
    method: 'POST',
    body: {},
    headers: {
      [SourceHeaderKey]: trackingSourceCookie.value,
    },
  });

  sessionCookie.value = sessionId;
}
