import { SourceHeaderKey } from '@locam/common-services';

import type { RentalConfiguration } from '../../catalog/rent';
import { getBasketBaseUrl } from '..';
import { type Basket } from '../get-basket/utils';

export class ApiBasketError extends Error {}
export class ApiBasketAddToBasketError extends ApiBasketError {}
export interface BasketItemCreateBody {
  productId: string;
  addressId?: string;
  rentalDurationInMonths: number;
  quantity: number;
  consumables?: {
    consumableId: string;
    quantity: number;
  }[];
  serviceId?: string;
  options?: string[];
}

export async function addToBasket(
  configuration: RentalConfiguration,
  addressId?: string,
): Promise<{ basket: Basket; basketItemId: string }> {
  const trackingSourceCookie = useCookie(SourceHeaderKey);
  const {
    $api: { authenticatedOrAnonymousFetch },
  } = useNuxtApp();

  if (!trackingSourceCookie.value) {
    throw new ApiBasketAddToBasketError('Failed to add product to basket');
  }

  const body: BasketItemCreateBody = {
    ...configuration,
    addressId,
  };

  return authenticatedOrAnonymousFetch<{ basket: Basket; basketItemId: string }>('/items', {
    baseURL: getBasketBaseUrl(),
    method: 'POST',
    body,
    headers: {
      [SourceHeaderKey]: trackingSourceCookie.value,
    },
  });
}
