import { callWithNuxt } from '#app';
import { ANONYMOUS_SESSION_COOKIE_NAME, useAnonymousSessionId } from '~/composables/useAnonymousSessionId';
import { addToBasket as addToBasketRequest } from '~/utils/api/basket/add-to-basket';
import { applyPromoCode as applyPromoCodeRequest } from '~/utils/api/basket/apply-promo-code';
import { confirmBasket } from '~/utils/api/basket/confirm-basket';
import { deleteBasketItem as deleteBasketItemRequest } from '~/utils/api/basket/delete-basket-item';
import { duplicateBasketItem as duplicateBasketItemRequest } from '~/utils/api/basket/duplicate-basket-item';
import { getBasket } from '~/utils/api/basket/get-basket';
import { type Basket, type BasketProductItemId } from '~/utils/api/basket/get-basket/utils';
import { transferBasket as transferBasketRequest } from '~/utils/api/basket/transfer-basket';
import { updateAddressGroup as updateAddressGroupRequest } from '~/utils/api/basket/update-address-group';
import { updateBasketItem as updateBasketItemRequest } from '~/utils/api/basket/update-basket-item';
import type { RentalConfiguration } from '~/utils/api/catalog/rent';
import { createAnonymousOrganization, getAnonymousOrganization } from '~/utils/api/organization/anonymous-organization';
import type { AddressId } from '~/utils/api/organization/user-organization/utils';

class TransferBasketError extends Error {}

export class TransferBasketMissingAnonymousSessionError extends TransferBasketError {}

export class TransferBasketMissingAnonymousOrganizationError extends TransferBasketError {}

const checkoutBasket = async (): Promise<void> => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated.value) {
    await confirmBasket();

    await navigateTo({
      name: 'billing-index-information',
    });

    return;
  }

  const nuxtApp = useNuxtApp();
  const basketRedirectUri = await callWithNuxt(nuxtApp, () =>
    useLink({
      to: {
        name: 'basket',
        query: {
          checkout: 1,
        },
      },
    }),
  );

  const signInRoute = useSignInRoute(basketRedirectUri.href.value);

  await navigateTo(signInRoute.value, { external: true });
};

const transferBasket = async () => {
  const anonymousSessionId = useAnonymousSessionId();

  if (!anonymousSessionId.value) {
    throw new TransferBasketMissingAnonymousSessionError('Missing anonymous session');
  }

  await getAnonymousOrganization();

  const {
    public: { siteUrl },
  } = useRuntimeConfig();

  const url = new URL(
    `${siteUrl}/basket?${new URLSearchParams({
      [ANONYMOUS_SESSION_QUERY_PARAM_NAME]: anonymousSessionId.value,
    }).toString()}`,
  );

  await transferBasketRequest(window.btoa(url.toString()));
};

export default async function useBasket() {
  const basket = useState('basket', () => ref<Basket>());

  if (!basket.value) {
    const { isOrganizationUser } = useAuth();
    const anonymousSessionId = useAnonymousSessionId();

    if (isOrganizationUser.value || anonymousSessionId.value) {
      basket.value = await getBasket();

      if (basket.value.shouldDeleteAnSession) {
        const nuxtApp = useNuxtApp();

        const anonymousSessionCookie = await callWithNuxt(nuxtApp, () => useCookie(ANONYMOUS_SESSION_COOKIE_NAME));

        anonymousSessionCookie.value = undefined;
      }
    }
  }

  const addToBasket = async (configuration: RentalConfiguration, addressId?: string): Promise<string> => {
    const { isOrganizationUser } = useAuth();
    const anonymousSessionId = useAnonymousSessionId();

    if (!isOrganizationUser.value && !anonymousSessionId.value) {
      await createAnonymousOrganization();
    }

    const { basket: newBasket, basketItemId } = await addToBasketRequest(configuration, addressId);

    basket.value = newBasket;

    return basketItemId;
  };

  const updateBasketItem = async (
    basketItemId: BasketProductItemId,
    configuration: RentalConfiguration,
    addressId?: AddressId,
  ): Promise<void> => {
    basket.value = await updateBasketItemRequest(basketItemId, configuration, addressId);
  };

  const duplicateBasketItem = async (basketItemId: BasketProductItemId): Promise<string> => {
    const { basket: newBasket, basketItemId: newBasketItemId } = await duplicateBasketItemRequest(basketItemId);

    basket.value = newBasket;

    return newBasketItemId;
  };

  const deleteBasketItem = async (basketItemId: BasketProductItemId): Promise<void> => {
    basket.value = await deleteBasketItemRequest(basketItemId);
  };

  const updateAddressGroup = async (previousAddressId: AddressId, newAddressId: AddressId): Promise<void> => {
    basket.value = await updateAddressGroupRequest(previousAddressId, newAddressId);
  };

  const applyPromoCode = async (promoCode: string): Promise<void> => {
    basket.value = await applyPromoCodeRequest(promoCode);
  };

  return {
    basket,
    basketCount: computed<number>(
      () =>
        basket.value?.shippingAddressGroups.reduce<number>((itemCount, shippingGroup) => {
          for (const { items } of shippingGroup.items) {
            itemCount += items.length;
          }

          return itemCount;
        }, 0) ?? 0,
    ),
    addToBasket,
    updateBasketItem,
    duplicateBasketItem,
    deleteBasketItem,
    updateAddressGroup,
    applyPromoCode,
    checkoutBasket,
    transferBasket,
  };
}
