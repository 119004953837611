import { type Basket } from '~/utils/api/basket/get-basket/utils';

import { getBasketBaseUrl } from '..';

export async function applyPromoCode(promoCode: string): Promise<Basket> {
  const {
    $api: { authenticatedOrAnonymousFetch },
  } = useNuxtApp();

  return authenticatedOrAnonymousFetch<Basket>('/promo-code', {
    baseURL: getBasketBaseUrl(),
    method: 'POST',
    body: {
      promoCode,
    },
  });
}
