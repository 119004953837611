import type { AddressId } from '~/utils/api/organization/user-organization/utils';

import { getBasketBaseUrl } from '..';
import { type Basket } from '../get-basket/utils';

export async function updateAddressGroup(previousAddressId: AddressId, newAddressId: AddressId): Promise<Basket> {
  const {
    $api: { authenticatedOrAnonymousFetch },
  } = useNuxtApp();

  return authenticatedOrAnonymousFetch<Basket>(`/items/addresses/${encodeURIComponent(previousAddressId)}`, {
    baseURL: getBasketBaseUrl(),
    method: 'PATCH',
    body: {
      newAddressId,
    },
  });
}
