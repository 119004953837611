import { getBasketBaseUrl } from '..';
import type { Basket } from '../get-basket/utils';

export async function duplicateBasketItem(basketItemId: string): Promise<{ basket: Basket; basketItemId: string }> {
  const {
    $api: { authenticatedOrAnonymousFetch },
  } = useNuxtApp();

  return authenticatedOrAnonymousFetch<{ basket: Basket; basketItemId: string }>(
    `/items/${encodeURIComponent(basketItemId)}/duplicate`,
    {
      baseURL: getBasketBaseUrl(),
      method: 'POST',
    },
  );
}
