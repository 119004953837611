import { type MaybeRefOrGetter, toValue } from 'vue';

import { StateParameterRedirectUri } from '~/modules/auth/runtime/utils/state-utils';

export default function useSignInRoute(
  redirectUri?: MaybeRefOrGetter<string>,
  payload?: MaybeRefOrGetter<ConstructorParameters<typeof URLSearchParams>[0]>,
) {
  const {
    public: {
      auth: {
        route: { signIn: signInRoute },
      },
    },
  } = useRuntimeConfig();

  return computed<string>(() => {
    const payloadValue = toValue(payload);
    const query = new URLSearchParams(payloadValue);

    const redirectUriValue = toValue(redirectUri);

    if (redirectUriValue) {
      query.set(StateParameterRedirectUri, redirectUriValue);
    }

    return `${signInRoute}${query.size > 0 ? `?${query.toString()}` : ''}`;
  });
}
