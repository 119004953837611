import { getBasketBaseUrl } from '..';
import type { Basket } from '../get-basket/utils';

export async function deleteBasketItem(basketItemId: string): Promise<Basket> {
  const {
    $api: { authenticatedOrAnonymousFetch },
  } = useNuxtApp();

  return authenticatedOrAnonymousFetch<Basket>(`/items/${encodeURIComponent(basketItemId)}`, {
    baseURL: getBasketBaseUrl(),
    method: 'DELETE',
  });
}
