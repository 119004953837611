import { getBasketBaseUrl } from '..';

export async function transferBasket(link: string): Promise<void> {
  const {
    $api: { authenticatedOrAnonymousFetch },
  } = useNuxtApp();

  await authenticatedOrAnonymousFetch('/baskets/me/transfer', {
    baseURL: getBasketBaseUrl(),
    method: 'POST',
    body: {
      link,
    },
  });
}
