import type { AddressId } from '~/utils/api/organization/user-organization/utils';

import type { RentalConfiguration } from '../../catalog/rent';
import { getBasketBaseUrl } from '..';
import type { BasketItemCreateBody } from '../add-to-basket';
import { type Basket } from '../get-basket/utils';

export async function updateBasketItem(
  basketItemId: string,
  configuration: RentalConfiguration,
  addressId?: AddressId,
): Promise<Basket> {
  const {
    $api: { authenticatedOrAnonymousFetch },
  } = useNuxtApp();

  const body: BasketItemCreateBody = {
    ...configuration,
    addressId,
  };

  return authenticatedOrAnonymousFetch<Basket>(`/items/${encodeURIComponent(basketItemId)}`, {
    baseURL: getBasketBaseUrl(),
    method: 'PATCH',
    body,
  });
}
