import { getBasketBaseUrl } from '..';

export async function confirmBasket(): Promise<void> {
  const {
    $api: { authenticatedOrAnonymousFetch },
  } = useNuxtApp();

  await authenticatedOrAnonymousFetch('/basket/confirm', {
    baseURL: getBasketBaseUrl(),
    method: 'PUT',
  });
}
