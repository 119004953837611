import { getQuery, type H3Event } from 'h3';
import { type ParsedQuery, parseQuery } from 'ufo';

export const StateParameterName = 'state';

export const StateParameterPolicy = 'policy';
export const StateParameterRedirectUri = 'redirectUri';

export function parseStateFromQuery<T extends ParsedQuery = ParsedQuery>(event: H3Event): Partial<T> {
  const query = getQuery<{ [StateParameterName]?: string | string[] }>(event);

  if (query[StateParameterName]) {
    const state = Array.isArray(query[StateParameterName])
      ? query[StateParameterName].at(0)
      : query[StateParameterName];

    if (state) {
      return parseQuery<T>(state);
    }
  }

  return {};
}
