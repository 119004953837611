import { type Basket } from '~/utils/api/basket/get-basket/utils';

import { getBasketBaseUrl } from '..';

export async function getBasket(): Promise<Basket> {
  const {
    $api: { authenticatedOrAnonymousFetch },
  } = useNuxtApp();

  return authenticatedOrAnonymousFetch<Basket>('/baskets/me', {
    baseURL: getBasketBaseUrl(),
  });
}
